/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/components/layout.css';

// onClientEntry() must be included for the requires above to be triggered,
// even if it is empty!
export const onClientEntry = () => {};
